export function createSearchCandidates(text: string) {
  let numberOfCharacters = 1; // 利用する文字数
  let count = -1; // 利用する文字数を用いた時のループ数

  return Array((1 + text.length) * (text.length / 2))
    .fill(0)
    .map(() => {
      // 組み合わせを変えない状態の、全パターンをリストに追加
      if (count + 1 == text.length - numberOfCharacters + 1) {
        // 指定文字数でのパターンを、取得し終えた場合(規定のループ回数に達した場合)
        numberOfCharacters++;
        count = 0;
      } else {
        count++;
      }

      return {
        [text.slice(count, count + numberOfCharacters).toLowerCase()]: true,
      };
    })
    .reduce((l, r) => Object.assign(l, r), {});
}
